import React from "react";
import { Outlet } from "@remix-run/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

export function AnimatedOutlet() {
  const [parent] = useAutoAnimate({
    duration: 300,
  });
  return (
    <div ref={parent}>
      <Outlet />
    </div>
  );
}
